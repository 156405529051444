export default {
  title: "عملية بحث المستخدم",
  entityName: "userQuery",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "التسمية",
      value: "name",
      align: "right",
      field: "_name",
      sortable: false,
    },
    {
      text: "#UID المستخدم",
      value: "userId",
      align: "right",
      field: "_userId",
      sortable: false,
    },
    {
      text: "بحث المستخدم ",
      value: "query",
      align: "right",
      field: "_query",
      sortable: false,
    },
    {
      text: "البريد الإلكتروني",
      value: "email",
      align: "right",
      field: "_email",
      sortable: false,
    },
    {
      text: "الاسم الأول للمستخدم",
      value: "userFirstName",
      align: "right",
      field: "_userFirstName",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "الاسم الأخير للمستخدم",
      value: "userLastName",
      align: "right",
      field: "_userLastName",
      sortable: false,
      restrictSort: true,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field, restrictSort }) => {
      if (!restrictSort) {
        newArr.push({ text, field });
      }
    });

    return newArr;
  },
};
