<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="fields.name"
          :rules="nameRules"
          label="التسمية"
          required
        ></v-text-field>

        <v-text-field
          v-model="fields.query"
          :rules="queryRules"
          label="استفسار"
          required
        ></v-text-field>

        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          clearable
          hide-details
          hide-selected
          item-text="firstName"
          item-value="id"
          label="ابحث هنا"
          class="mb-5"
        >
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-text v-bind="attr" :input-value="selected" v-on="on">
              <span v-text="item.firstName"></span>
            </v-text>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                v-text="item.firstName + ' ' + item.lastName"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="item.email"> </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-btn class="mr-4" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { searchUser } from "@services/User.api";
export default {
  data: () => ({
    fields: { name: "", query: "", userId: 0 },
    valid: true,
    nameRules: [(v) => !!v || "Name is required"],
    queryRules: [(v) => !!v || "Query is required"],
    isLoading: false,
    items: [],
    model: null,
    search: null,
  }),

  methods: {
    ...mapActions("userQueries", ["addUserQuery"]),
    submit() {
      this.fields.userId = this.model;
      this.addUserQuery(JSON.stringify(this.fields)).then(() => {
        this.$router.push({ name: "BackendUserQuery" });
      });
    },
    goBack() {
      this.$router.push({ name: "BackendUserQuery" });
    },
  },
  watch: {
    search(val) {
      if (!val) return;

      this.isLoading = true;

      searchUser(val)
        .then((res) => {
          this.items = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
