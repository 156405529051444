var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mb-6"},[_c('v-card',{staticStyle:{"width":"98%","max-width":"1000px"},attrs:{"elevation":"0"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":""},on:{"click":_vm.goBack}},[_c('span',{staticClass:"ml-2 mb-1",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" رجوع ")]),_c('v-icon',[_vm._v("mdi-backburger")])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":"التسمية","required":""},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('v-text-field',{attrs:{"rules":_vm.queryRules,"label":"استفسار","required":""},model:{value:(_vm.fields.query),callback:function ($$v) {_vm.$set(_vm.fields, "query", $$v)},expression:"fields.query"}}),_c('v-autocomplete',{staticClass:"mb-5",attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"clearable":"","hide-details":"","hide-selected":"","item-text":"firstName","item-value":"id","label":"ابحث هنا"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-text',_vm._g(_vm._b({attrs:{"input-value":selected}},'v-text',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.firstName)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.firstName + ' ' + item.lastName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.email)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"depressed":"","outlined":"","large":""},on:{"click":_vm.goBack}},[_c('span',{staticClass:"ml-2 mt-1",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" إلغاء ")]),_c('v-icon',[_vm._v("cancel")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"depressed":"","outlined":"","large":""},on:{"click":_vm.submit}},[_c('span',{staticClass:"ml-2 mt-1",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" حفظ ")]),_c('v-icon',[_vm._v("save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }